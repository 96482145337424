import { BaseController } from "@stimulus-library/utilities";
import { installClassMethods, useEventListener } from "@stimulus-library/mixins";
export class CharCountController extends BaseController {
    // Config
    static targets = ["input", "output"];
    static values = { min: Number, max: Number };
    static classes = ["error"];
    connect() {
        installClassMethods(this);
        requestAnimationFrame(() => {
            useEventListener(this, this.inputTarget, "input", this._updateCharCount);
            this._updateCharCount();
        });
    }
    _updateCharCount() {
        let charCount = this.inputTarget.value.length;
        this.outputTarget.innerText = charCount.toString();
        if (this._isValidCount(charCount)) {
            this.removeErrorClasses(this.outputTarget);
        }
        else {
            this.addErrorClasses(this.outputTarget);
        }
    }
    _isValidCount(count) {
        let min = 0;
        let max = 99999;
        if (this.hasMinValue) {
            min = this.minValue;
        }
        if (this.hasMaxValue) {
            max = this.maxValue;
        }
        return count >= min && count <= max;
    }
}
