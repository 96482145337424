import { LoadBlockController } from "./load_block_controller";
import { useIntersection } from "@stimulus-library/mixins";
export class LazyBlockController extends LoadBlockController {
    async connect() {
        let element = this.el;
        if ("IntersectionObserver" in window) {
            let { observe, unobserve } = useIntersection(this, element, this.appear, null, { threshold: 0.3 });
            this.observe = observe;
            this.unobserve = unobserve;
        }
        else {
            // Client doesn't support intersection observer, fallback to pre-loading
            await this.loadContent();
        }
    }
    async appear(entry) {
        if (entry.target === this.el && entry.isIntersecting) {
            await this.loadContent();
            if (this.unobserve) {
                this.unobserve();
            }
        }
    }
}
