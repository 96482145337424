import { BaseController } from "@stimulus-library/utilities";
import { useFullscreen } from "@stimulus-library/mixins";
export class FullscreenController extends BaseController {
    connect() {
        let { enter, exit, toggle } = useFullscreen(this);
        this.enter = enter;
        this.exit = exit;
        this.toggle = toggle;
    }
}
