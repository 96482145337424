import { BaseController, EasingFunctions } from "@stimulus-library/utilities";
import { useIntersection } from "@stimulus-library/mixins";
export class TweenNumberController extends BaseController {
    static values = {
        start: Number,
        end: Number,
        duration: Number,
        easing: String,
        formatting: {
            type: Object,
            default: {},
        },
    };
    observer;
    observe;
    unobserve;
    teardownObserver;
    get start() {
        if (this.hasStartValue) {
            return this.startValue;
        }
        else {
            throw new Error("Required value `start` is missing");
        }
    }
    get end() {
        if (this.hasEndValue) {
            return this.endValue;
        }
        else {
            throw new Error("Required value `end` is missing");
        }
    }
    get durationMs() {
        if (this.hasDurationValue) {
            return this.durationValue;
        }
        else {
            throw new Error("Required value `duration` is missing");
        }
    }
    get easingFunction() {
        let str = this.hasEasingValue ? this.easingValue : null;
        let fallback = EasingFunctions.linear;
        if (str == null) {
            return fallback;
        }
        // @ts-ignore
        return EasingFunctions[str] || fallback;
    }
    connect() {
        let { observer, observe, unobserve, teardown, } = useIntersection(this, this.el, this.appear);
        this.observer = observer;
        this.observe = observe;
        this.unobserve = unobserve;
        this.teardownObserver = teardown;
        this.formatter = new Intl.NumberFormat(Intl.NumberFormat().resolvedOptions().locale, this.formattingValue);
    }
    appear(_entry) {
        this.tween();
    }
    ;
    tween() {
        let startTimestamp = null;
        let self = this;
        const step = (timestamp) => {
            if (!startTimestamp) {
                startTimestamp = timestamp;
            }
            const elapsed = timestamp - startTimestamp;
            const progress = Math.min(elapsed / this.durationMs, 1);
            this.element.innerHTML = this.formatter.format(Math.floor(this.easingFunction(progress) * (this.end - this.start) + this.start));
            if (progress < 1) {
                requestAnimationFrame(step);
            }
            else {
                self.unobserve?.();
                self.teardownObserver?.();
            }
        };
        requestAnimationFrame(step);
    }
}
