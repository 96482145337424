import { BaseController } from "@stimulus-library/utilities";
import { installClassMethods, useEventListener } from "@stimulus-library/mixins";
export class AnchorSpyController extends BaseController {
    static values = { key: String };
    static classes = ["active", "inactive"];
    get defaultActiveClasses() {
        return ["active"];
    }
    get defaultInactiveClasses() {
        return ["inactive"];
    }
    get _key() {
        // @ts-ignore
        return this.keyValue.replaceAll('#', '');
    }
    get _anchor() {
        return window.location.hash.substr(1);
    }
    set _anchor(value) {
        window.location.hash = value;
    }
    connect() {
        installClassMethods(this);
        requestAnimationFrame(() => {
            this._checkAnchor();
            useEventListener(this, window, 'hashchange', this._checkAnchor);
        });
    }
    write(event) {
        event?.preventDefault();
        this._anchor = this._key;
    }
    _checkAnchor() {
        if (this._key === this._anchor) {
            this.dispatchEvent(this.el, this.eventName("active"));
            this.addActiveClasses(this.el);
            this.removeInactiveClasses(this.el);
        }
        else {
            this.dispatchEvent(this.el, this.eventName("inactive"));
            this.addInactiveClasses(this.el);
            this.removeActiveClasses(this.el);
        }
    }
}
