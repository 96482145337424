import { BaseController, requestReset, requestSubmit } from "@stimulus-library/utilities";
export class FormRcController extends BaseController {
    static targets = ["form"];
    static values = {
        formSelector: String,
        submitMode: String,
    };
    get _mode() {
        if (this.hasSubmitModeValue) {
            if (!["direct", "request"].includes(this.submitModeValue)) {
                throw new Error(`The modeValue provided '${this.submitModeValue}' is not one of the recognised configuration options`);
            }
            return this.submitModeValue;
        }
        else {
            return "request";
        }
    }
    get form() {
        let form;
        if (this.hasFormSelectorValue) {
            form = document.querySelector(this.formSelectorValue);
            if (!form) {
                throw new Error(`Could not find a form on the page that matches selector '${this.formSelectorValue}'`);
            }
        }
        else {
            form = this.formTarget;
        }
        return form;
    }
    submit(event) {
        event?.preventDefault();
        let el = this.form;
        if (this._mode == 'request') {
            requestSubmit(el);
        }
        else {
            el.submit();
        }
    }
    reset(event) {
        event?.preventDefault();
        requestReset(this.form);
    }
}
