import { EphemeralController } from "@stimulus-library/utilities";
export class TeleportController extends EphemeralController {
    static values = { target: String, insert: String, immediate: Boolean };
    connect() {
        if (!this.hasInsertValue) {
            throw new Error("`insert` value was not specified");
        }
        requestAnimationFrame(() => {
            if (this.hasImmediateValue && this.immediateValue) {
                this.execute();
            }
        });
    }
    execute(event) {
        event?.preventDefault();
        let element = this.el;
        let destination = document.querySelector(this.targetValue);
        if (destination == null) {
            this.dispatchEvent(element, this.eventName("error"));
            return;
        }
        let copy = element.cloneNode(true);
        this.cleanup(copy);
        switch (this.insertValue) {
            case "beforebegin":
            case "beforeend":
            case "afterend":
            case "afterbegin":
                destination.insertAdjacentHTML(this.insertValue, copy.outerHTML);
                break;
            case "replaceOuter":
                destination.outerHTML = copy.outerHTML;
                break;
            case "replaceInner":
                destination.innerHTML = copy.outerHTML;
                break;
            case "prepend":
                destination.insertAdjacentHTML("afterbegin", copy.outerHTML);
                break;
            case "append":
                destination.insertAdjacentHTML("beforeend", copy.outerHTML);
                break;
            default:
                throw new Error("`insert` value was not specified");
        }
        element.remove();
    }
}
